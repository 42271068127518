import { AvatarColors } from 'contants'
import TechnicianModel from 'models/technician'
import useStyles from './styles'

interface Props {
  technician: TechnicianModel
  isActive?: boolean
  style?: any
  onSelectTech?: (technician: TechnicianModel) => void
}

const TechnicianAvatar = (props: any) => {
  const styles = useStyles()
  const { technician, style, onPress } = props
  const avatarUrl = technician?.avatarUrl()

  if (!avatarUrl) {
    const color: any = AvatarColors[technician?.avatarColorIdx?.toString() ?? '7']

    return (
      <div className={`${styles.image} ${styles.empty} ${style}`} style={{ backgroundColor: 'white', border: `1px solid ${color}` }} onClick={onPress}>
        <span className={`${styles.txtChar}`} style={{ color: color, fontSize: (style?.width ?? 40) * 0.48 }}>{technician?.name?.slice(0, 1)?.toUpperCase()}</span>
      </div>
    )
  }

  return (
    <div className={`${styles.image} ${style}`} onClick={onPress}>
      <img src={avatarUrl} width="100%" height="100%" style={{ borderRadius: '8px' }} />
    </div >
  )
}

const TechnicianCard = (props: Props) => {
  const { technician, isActive, onSelectTech } = props
  const styles = useStyles()

  const onClick = onSelectTech ? () => onSelectTech(technician) : undefined

  return (
    <button disabled={!onSelectTech} key={technician?.id} onClick={onClick} className={`${styles.container} ${isActive && styles.itemActive}`}>
      <TechnicianAvatar technician={technician} />
      <p className={isActive && styles.txtActive}>{technician?.name}</p>
    </button>
  )
}

export default TechnicianCard
