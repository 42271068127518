import { forwardRef, useImperativeHandle, useState } from "react"

import Modal from 'react-modal'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: { zIndex: 999 }
}

const ModalComponent = forwardRef((props: any, ref: any) => {
  const [customModalStyles, setCustomModalStyles] = useState<any>(null)
  const [visible, setVisible] = useState<boolean>(false)
  const [data, setData] = useState<any>(null)

  const onCloseModal = () => setVisible(false)

  useImperativeHandle(ref, () => ({
    open: (data: any) => {
      setData(data.content)
      setCustomModalStyles(data.customModalStyles)
      setVisible(true)
    },
    close: () => {
      setData(null)
      setVisible(false)
    }
  }))

  return (
    <Modal
      isOpen={visible}
      onRequestClose={onCloseModal}
      contentLabel="Modal"
      style={customModalStyles ? customModalStyles : customStyles}
      ariaHideApp={false}
    >
      {data}
    </Modal>
  )
})

export default ModalComponent
