import logoDefault from 'assets/images/logo_default.png'

export default class ServiceModel {
  public id!: number
  public categoryId!: number
  public name!: string
  public price!: number
  public duration!: number
  public description!: string
  public refPrice!: number
  public raw!: string
  public thumbnail!: string
  public createdAt!: string
  public updatedAt!: string

  constructor(data: any) {
    if (data) {
      this.id = data.id
      this.categoryId = data.category_id
      this.name = data.name
      this.price = data.price
      this.duration = data.duration
      this.description = data.description
      this.refPrice = data.ref_price
      this.raw = data.raw
      this.thumbnail = data.thumbnail
      this.createdAt = data.created_at
      this.updatedAt = data.updated_at
    }
  }

  public getUrl(): string {
    return `${process.env.REACT_APP_MEDIA_URL}/services`
  }

  public imageUrl(): string {
    if (this.thumbnail && this.thumbnail !== '0') {
      return `${process.env.REACT_APP_MEDIA_URL}/services/${this.thumbnail}`
    }
    return logoDefault
  }
}