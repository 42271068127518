import { Helmet } from "react-helmet"
import { useParams } from "react-router-dom"
import React, { useCallback, useEffect, useState } from "react"
import moment from "moment"
import axios from 'axios'
import i18next from "i18next"
import { useTranslation } from "react-i18next"
import { IoSwapHorizontalOutline } from 'react-icons/io5'

import { API } from "stores/apis"
import * as StringUtils from 'common/utils/string'
import BookingTemplate1 from './components/template1'
import EmptyPage from './components/empty-page'
import useQuery from "common/custom-hooks/useQuery"
import { modalRef } from "utils/refs"
import Modal from './components/modal'
import 'moment/locale/vi'
import "../../../node_modules/flag-icons/css/flag-icons.min.css"

import './styles.css'
import { convertTzToUtc } from "common/time"
import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import SalonModel from "models/salon"

export enum Language {
  English = 'en',
  Vietnamese = 'vi'
}

interface State {
  name: string,
  phone: string,
  email: string,
  salon: SalonModel | null
  loading: boolean
  times: any
  dateComing: any
  timeComing: any
  services: any[]
  technician: any,
  note: string,
  isLoading: boolean,
  googleCaptchaToken: string | null,
  refreshReCaptcha: boolean,

  bookingSuccess: boolean
  userData: any

  showError: boolean
  refCode: string | null

  lang: Language
}

export enum SalonReferralActivityType {
	View = 1,
	Book = 2
}

const Booking = (props: any) => {
  const { slug } = useParams()
  const query = useQuery()
  const { t } = useTranslation()

  const [state, setState] = useState<State>({
    name: '',
    phone: '',
    email: '',
    salon: null,
    loading: false,
    times: [],

    dateComing: moment(),
    timeComing: null,
    services: [],
    technician: null,
    note: '',
    googleCaptchaToken: null,
    refreshReCaptcha: false,

    isLoading: false,
    bookingSuccess: false,
    userData: null,

    showError: false,
    refCode: null,

    lang: Language.English,
  })

  const handleStateChange = (value: any, field: string) => setState(prevState => ({ ...prevState, [field]: value }))

  const getUserData = async()=>{
    const res = await axios.get('https://geolocation-db.com/json/')
    handleStateChange(res.data, 'userData')
    return res.data
}

  const onAddSalonReferral = async (salonId: number) => {
    if (query.get('code')) {
      const data = await getUserData()

      if (data) handleStateChange(data, 'userData')
      await API.addSalonReferralActivity(salonId, query.get('code'), {
        type: SalonReferralActivityType.View,
        ip: data?.IPv4,
        data: data && JSON.stringify(data)
      })
    }
  }

  const onGetSalon = async () => {
    handleStateChange(true, 'loading')

    const slugArr = slug && slug.split('-')
    const lastSlug = slugArr && slugArr[slugArr.length - 1]
    const refCode = lastSlug && lastSlug.slice(0, lastSlug.indexOf('.html'))

    if (refCode) {
      const res: any = await API.getSalon({ refCode })
      if (res?.status === 200) {
        handleStateChange(new SalonModel(res?.data?.data), 'salon')
        handleStateChange(refCode, 'refCode')
        onAddSalonReferral(res.data.data.id)
      }
    }

    handleStateChange(false, 'loading')
  }

  useEffect(() => {
    onGetSalon()
    moment.locale('en')
  }, [])

  useEffect(() => {
    if (state.salon) {
      const focusDate = state.salon.workingTime.find((item: any) => item.weekday === state.dateComing.isoWeekday())
      if (focusDate) {
        const fromDate = moment(focusDate.from, 'h:mm A')
        const arr = !state.dateComing.isSame(moment(), 'day') || fromDate.isAfter(moment(), 'minutes')
          ? [fromDate]
          : []

        let lastItem = moment(focusDate.from, 'h:mm A')
        let i = 1
        while(lastItem.isBefore(moment(focusDate.to, 'h:mm A').subtract(60, 'minutes'))) {
          lastItem = lastItem.add(30, 'minutes')
          
          if (!state.dateComing.isSame(moment(), 'day') || lastItem.isAfter(moment(), 'minutes')) {
            arr.push(moment(focusDate.from, 'h:mm A').add(30 * i, 'minutes'))
          }

          i++
        }
        handleStateChange(arr, 'times')
      } else {
        handleStateChange([], 'times')
      }
    }
  }, [state.dateComing, state.salon])

  const onSelectDay = (date: any) => handleStateChange(date, 'dateComing')
  const onSelectTime = (time: any) => handleStateChange(time, 'timeComing')
  
  const { name, phone, salon, times, dateComing, timeComing, services, technician,
    note, isLoading, bookingSuccess, loading, googleCaptchaToken, userData, email,
    refreshReCaptcha, showError } = state

  const onBooking = async () => {
    if((email && !StringUtils.validateEmail(email)) || !StringUtils.validatePhone(phone)){
      window.scrollTo(0,0)
      handleStateChange(true, 'showError')
      return
    }
    handleStateChange(true, 'isLoading')
    const s = services.map((item: any) => {
      const tId = technician
        ? salon?.techniciansServices.find((ts: any) => ts.serviceId === item.id && ts.technicianId === technician?.id)?.technicianId
        : null

      return {
        sId: item?.id,
        tId
      }
    })
  
    const res = await API.booking({
      name,
      phone,
      email,
      paxNumber: 1,
      salonId: salon?.id,
      startDateTime: convertTzToUtc(`${dateComing.format('YYYY-MM-DD')} ${timeComing.format('HH:mm:ss')}`, salon?.timezone?.timezone),
      note,
      type: 1,
      referralCode: query.get('code'),
      services: JSON.stringify(s),
      referralData: userData && JSON.stringify(userData),
      googleCaptchaToken,
    })

    handleStateChange(false, 'isLoading')
    
    if (res?.status === 200) {
      setState({
        ...state,
        isLoading: false,
        bookingSuccess: true
      })
    } else if ([400, 403].includes(res?.status ?? 0)) {
      alert(t(`booking.backendErrors.${res?.data?.message}`))
    } else {
      alert('Có lỗi xảy ra. Vui lòng thử lại sau!')
    }
  }

  const onSwitchingLanguage = () => {
    const newLang = state.lang === 'vi' ? 'en-US' : 'vi'
    handleStateChange(newLang, 'lang')
    i18next.changeLanguage(newLang)
    moment.locale(newLang)
  }
  const onVerifyCaptcha = useCallback((val: string) => handleStateChange(val, 'googleCaptchaToken'), [bookingSuccess])

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_CAPTCHA_V3_CLIENT_KEY ?? ''}
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined // optional, default undefined
      }}
      container={{
        element: "main",
        parameters: {
          badge: 'bottomleft', // optional, default undefined
          theme: 'dark', // optional, default undefined
        }
      }}>
      <main>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{salon?.name ?? t('booking.title')}</title>
        </Helmet>
        <div className="lang-box-ctr">
          <button className="lang-box" onClick={onSwitchingLanguage}>
            <p>
              <span className={`fi fi-${state.lang === 'vi' ? 'vn' : 'gb'} lang-box-flag`}></span>
              {t(`common.lang.${state.lang === 'vi' ? 'vietnamese' : 'english'}`)}
              <IoSwapHorizontalOutline size={18} className="lang-box-icon" />
            </p>
          </button>
        </div>
        {salon ? (
          <BookingTemplate1
            salon={salon}  times={times}
            dateComing={dateComing} timeComing={timeComing}
            onSelectDay={onSelectDay} onSelectTime={onSelectTime}
            name={name} phone={phone} note={note}
            handleStateChange={handleStateChange}
            services={services} technician={technician}
            onBooking={onBooking} refCode={state.refCode}
            isLoading={isLoading} showError={showError}
            bookingSuccess={bookingSuccess}
            googleCaptchaToken={googleCaptchaToken}
            email={email} refreshReCaptcha={refreshReCaptcha} />
            
        ) : !loading ? <EmptyPage /> : null}

        <GoogleReCaptcha
          onVerify={onVerifyCaptcha}
          refreshReCaptcha={refreshReCaptcha}
        />

        <Modal ref={modalRef} />
      </main>
    </GoogleReCaptchaProvider>
  )
}

export default Booking
