import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  modalList: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
  },

  viewCategory: {
    padding: '10px 10px 10px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  categoryName: {
    fontWeight: '600',
  },

})

export default useStyles
