import SalonImageModel, { SalonImageType } from "./salon-image"

import CategoriesModel from "./categories"
import SalonWorkingTimeModel from "./salon-working-time"
import ServiceModel from "./service"
import TechnicianServiceModel from "./technician-service"
import TechnicianModel from "./technician"
import { TimezoneModel } from "./timezone"

export enum SalonTypes {
  BeautySalon = 1,
  CarSalonGarage = 2,
  HouseCare = 3,
  EntertainmentAds = 4,
  Freelancer = 5
}

export const SalonTypeNames: any = {
  1: "Beauty",
  2: "Car Salon & Garage",
  3: "House Care",
  4: "Entertainment Ads",
  5: "Freelancer"
}

export default class SalonModel {
  public id!: number
  public reference_code!: number
  public location_id!: number
  public timezoneId!: number
  public type!: number
  public name!: string
  public email!: string
  public phone!: string
  public website!: string
  public description!: string
  public actualAddress!: string
  public createdBy!: number
  public createdAt!: number
  public updatedAt!: number
  public images!: SalonImageModel[]
  public workingTime!: SalonWorkingTimeModel[]
  public location!: any
  public categories!: CategoriesModel[]
  public services!: ServiceModel[]
  public technicians!: TechnicianModel[]
  public techniciansServices!: TechnicianServiceModel[]

  public timezone!: TimezoneModel

  constructor(data: any) {
    if (data) {
      this.id = data.id
      this.reference_code = data.reference_code
      this.location_id = data.location_id
      this.timezoneId = data.timezone_id
      this.type = data.type
      this.name = data.name
      this.phone = data.phone
      this.email = data.email
      this.phone = data.phone
      this.website = data.website
      this.description = data.description
      this.actualAddress = data.actualAddress
      this.timezone = data.timezone
      this.createdBy = data.created_by
      this.createdAt = data.created_at
      this.updatedAt = data.updated_at
      if (data.images) {
        this.images = data.images.map((item: SalonImageModel) => new SalonImageModel(item))
      }
      if (data.working_time) {
        this.workingTime = data.working_time.map((item: SalonWorkingTimeModel) => new SalonWorkingTimeModel(item))
      }

      this.location = data.location

      if (data.categories) {
        this.categories = data.categories.map((item: CategoriesModel) => new CategoriesModel(item))
      }
      if (data.services) {
        this.services = data.services.map((service: ServiceModel) => new ServiceModel(service))
      }
      if (data.technicians) {
        this.technicians = data.technicians.map((technician: TechnicianModel) => new TechnicianModel(technician))
      }
      if (data.techniciansServices) {
        this.techniciansServices = data.techniciansServices.map((technicianService: TechnicianServiceModel) => new TechnicianServiceModel(technicianService))
      }
    }
  }

  public get slug() {
    return `${this.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '')}-${this.reference_code}.html`
  }

  public get url() {
    return `${process.env.REACT_APP_MEDIA_URL}/salons`
  }

  public get avatar() {
    return this.images.find((image: SalonImageModel) => image.type === SalonImageType.Avatar)
  }

  public get banners() {
    return this.images.filter((image: SalonImageModel) => image.type === SalonImageType.Banner)
  }

  public get logoSource(): any {
    if (this.avatar?.thumbnail) return `${process.env.REACT_APP_MEDIA_URL}/salons/${this.avatar?.thumbnail}`
    return null
  }

  public get bannerSource(): any {
    if (this.banners.length > 0) return `${process.env.REACT_APP_MEDIA_URL}/salons/${this.banners[0]?.raw}`
    return null
  }

  public get slideBanners() {
    return this.banners.map((b: SalonImageModel) => ({
      original: `${process.env.REACT_APP_MEDIA_URL}/salons/${b.raw}`,
      thumbnail: `${process.env.REACT_APP_MEDIA_URL}/salons/${b.thumbnail}}`
    }))
  }
}
