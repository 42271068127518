

import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducers from './reducers'
import rootSagas from './sagas'

var store: any = null

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]

const configureStore = () => {
  if (store) return store
  let enhancers

  enhancers = compose(applyMiddleware(...middlewares))

  store = createStore(rootReducers, enhancers)

  sagaMiddleware.run(rootSagas)
  return store
}

export default configureStore()
