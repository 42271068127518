const booking = {
  title: 'Book an appointment',
  bookingSuccess: 'Booking successfully!',
  phone: 'Phone',
  email: 'Email',
  fullName: 'Full name',
  date: 'Date',
  dayComing: 'Day coming',
  timeComing: 'Time coming',
  service: 'Service',
  services: 'Services',
  technicians: 'Technicians',
  optional: 'Optional',
  note: 'Note',
  bookAnAppointment: 'Booking',
  selectService: 'Select service',
  noServiceYet: 'No service yet',
  addService: 'Add service',
  emptySalon: 'SALON NOT FOUND!',
  emptySalonDescription: 'Please contact salon to get a booking link.',
  msgInvalidEmail:'Email is invalid',
  msgInvalidPhone:'Phone is invalid',
  selectServices: 'Select services',
  closed: 'Closed',
  createAnAccount: 'Create an account',
  alreadyAnAccount: 'Already have an account?',
  loginNow: 'Login now',
  noServicesWithKeyword: 'No services with your keyword',

  backendErrors: {
    captchaInvalid: 'Captcha is invalid. Please refresh the page again.',
    youBlocked: 'You has been blocked.',
    youAreBot: 'You are a bot.',
    nameRequired: 'Name is required.',
    phoneRequired: 'Phone is required.',
    salonRequired: 'Salon is required.',
    startDateTimeRequired: 'Start date time is required.',
    typeRequired: 'Type is required.',
    paxNumberRequired: 'Pax Number is required.',
  }
}

export default booking