const common = {
  ok: 'OK',
  done: 'Done',
  cancel: 'Cancel',
  confirm: 'Confirm',
  delete: 'Delete',
  allow: 'Allow',
  clear: 'Clear',
  setup: 'Setup',
  update: 'Update',
  enable: 'Enable',
  save: 'Save',
  logout: 'Logout',
  lang: {
    english: 'English',
    vietnamese: 'Vietnamese'
  }
}

export default common