import { Colors } from 'contants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  modalList: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
  },

  categoryName: {
    fontWeight: '600',
    paddingTop: '10px',
  },

  txtEmpty: {
    fontSize: '16px',
    color: Colors.DARKGRAY8,
    fontStyle: 'italic',
  }
})

export default useStyles
