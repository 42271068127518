import * as actions from '../actions';

const test = (state = {}, action: any) => {
  switch (action.type) {
  case actions.TEST_ACTION: {
    return action.payload;
  }
  default:
    return state;
  }
};

export default test