import moment from "moment-timezone"

export const convertTzToUtc = (date: string, tz?: string, format: string = 'YYYY-MM-DD HH:mm:ss') => {
    if (date && tz) {
        const str_date = moment(date).format('YYYY-MM-DD HH:mm:ss')
        return moment.tz(str_date, tz).utc().format(format)
    }
    return ''
}

export const convertUtcToTz = (date: string, tz: string, format: string = 'YYYY-MM-DD HH:mm:ss') => {
    // const str_date = moment(date).format('YYYY-MM-DD HH:mm:ss')
    if (date && tz) return moment.utc(date).tz(tz).format(format)
    return ''
}