import { useTranslation } from 'react-i18next'
import './styles.css'

const EmptyState = (props: any) => {
  const { t } = useTranslation()

  return (
    <div className="empty-state-wrapper">
      <h1 className="empty-state-404">404</h1>
      <h6 className="empty-state-body">{t('booking.emptySalon')}</h6>
      <p className="empty-state-description">{t('booking.emptySalonDescription')}</p>
    </div>
  )
}

export default EmptyState
