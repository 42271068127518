import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'

import useStyles from './styles'
import SectionList from './components/section-list'
import ServicesSearchResults from './components/services-search-results'
import ServiceModel from 'models/service'

type State = {
  selected: any
  keyword: string
  searchResults: ServiceModel[]
  hiddenLists: number[]
}

const AddServicesModalContent = (props: any) => {
  const { salon, handleStateChange } = props
  const { t } = useTranslation()
  const styles = useStyles()
  const [state, setState] = useState<State>({
    selected: props?.services || [],
    keyword: '',
    searchResults: [],
    hiddenLists: []
  })

  const updateState = (newState: any) => setState((prevState: any) => ({ ...prevState, ...newState }))

  // Debounce callback
  const filterService = useDebouncedCallback(() => {
    const services = salon.services.filter((s: ServiceModel) => s.name.toLowerCase().indexOf(state.keyword.toLowerCase()) > -1)
    updateState({ searchResults: services })
  }, 300)

  useEffect(() => {
    filterService()
  }, [state.keyword])

  const { onCloseModal } = props

  const onSelected = (service: any) => {
    const index = state.selected.findIndex((i: any) => i.id === service.id)
    if (index === -1) {
      updateState({ selected: [...state.selected, service] })
    } else {
      updateState({ selected: state.selected.filter((i: any) => i.id !== service.id) })
    }
  }

  const onToggleCategory = (categoryId: number) => {
    let hl: number[] = state.hiddenLists
    if (state.hiddenLists.includes(categoryId)) {
      hl = state.hiddenLists.filter((i: number) => i !== categoryId)
    } else {
      hl.push(categoryId)
    }

    console.log(60, hl)
    updateState({ hiddenLists: hl })
  }

  const onConfirm = () => {
    handleStateChange(state.selected, 'services')
    onCloseModal()
  }

  const onChangeKeyword = (e: any) => updateState({ keyword: e.target.value })

  return (
    <div className={styles.modal}>
      <div className={styles.modalHeader}>
        {t('booking.selectServices')}
      </div>
      <input type="text" placeholder='Search service' className={styles.bookingInput} onChange={onChangeKeyword} />
      {state.keyword.length > 0 ? (
        <ServicesSearchResults salon={salon} searchResults={state.searchResults} selected={state.selected} onSelected={onSelected} />
      ) : <SectionList salon={salon} selected={state.selected} onSelected={onSelected} onToggleCategory={onToggleCategory} hiddenLists={state.hiddenLists} />}
      <div className={styles.modalFooter}>
        <button className={styles.modalButtonClose} onClick={() => onCloseModal()}>
          <span>{t('common.cancel')}</span>
        </button>
        <button className={styles.modalButton} onClick={onConfirm}>
          <span>{t('common.confirm')}</span>
        </button>
      </div>
    </div>
  )
}

export default AddServicesModalContent
