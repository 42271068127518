import useStyles from './styles'

const TimeOptionCard = (props: any) => {
  const styles = useStyles()
  const { time, isActive, onSelect } = props


  return (
    <div className={`${styles.timeBox} ${isActive && styles.timeBoxActive}`} onClick={() => onSelect(time)}>
      <div className={styles.timeNumber}>
        {time.format('H:mm')}
      </div>
    </div >
  )
}

export default TimeOptionCard