const common = {
  ok: 'OK',
  done: 'Xong',
  cancel: 'Hủy',
  confirm: 'Xác nhận',
  delete: 'Xóa',
  allow: 'Cho phép',
  clear: 'Xóa',
  setup: 'Cài đặt',
  update: 'Cập nhật',
  enable: 'Mở',
  save: 'Lưu',
  logout: 'Đăng xuất',
  lang: {
    english: 'Tiếng Anh',
    vietnamese: 'Tiếng Việt'
  }
}

export default common