import useStyles from './styles'

interface Props {
  avatarUrl?: any
  style?: any
  onPress?: () => void
}

export const Avatar = (props: Props) => {
  const styles = useStyles()
  const { avatarUrl, style, onPress } = props

  return (
    <div className={`${styles.image} ${style}`} onClick={onPress}>
      <img src={avatarUrl} width="100%" height="100%" style={{ borderRadius: '8px' }} />
    </div >
  )
}
