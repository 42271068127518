import moment from "moment"
import { Circles } from  'react-loader-spinner'
import React from "react"
import { useTranslation } from "react-i18next"
import { IoCalendarClearOutline, IoFileTrayFullOutline, IoPeopleCircleOutline, IoCreateOutline, IoPersonOutline, IoMailOutline, IoCallOutline } from 'react-icons/io5'
import ImageGallery from 'react-image-gallery'

import { EmptyState, ServiceCard } from "components"
import useStyles from './styles'

import ChooseServices from "./components/add-services-modal-content"
import DateOptionCard from "./components/date-option-card"
import TimeOptionCard from "./components/time-option-card"
import TechnicianCard from "./components/technician-card"

import { modalRef } from "utils/refs"
import bookingEmpty from 'assets/images/booking_empty.png'
import { stringUtils } from "utils"
import './styles.css'
import "react-image-gallery/styles/css/image-gallery.css"

const BookingSuccessView = React.memo((props: any) => {
  const { t, name, phone, email, dateComing, timeComing, services, renderService, technician, note, refCode } = props
  const styles = useStyles()

  const onLogin = () => {
    window.location.href = `https://pri.locaworld.net/login/${refCode}`
  }

  const onRegister = () => {
    window.location.href = `https://pri.locaworld.net/sign-up/${refCode}?name=${name}&phone=${phone}&email=${email}`
  }

  return (
    <div className="booking-template1-success">
      <h3 className="booking-template1-success-text">{t('booking.bookingSuccess')}</h3>

      <h6 className="booking-title">{t('booking.fullName')}:</h6>
      <h6 className="booking-info-address">{name}</h6>

      <h6 className="booking-title">{t('booking.phone')}:</h6>
      <h6 className="booking-info-address">{phone}</h6>

      {email ? (
        <>
          <h6 className="booking-title">{t('booking.email')}:</h6>
          <h6 className="booking-info-address">{email}</h6>
        </>
      ) : null}

      <h6 className="booking-title">{t('booking.date')}:</h6>
      <h6 className="booking-info-address">{dateComing.format('DD/MM/YYYY')} {timeComing.format('HH:mm')}</h6>

      <h6 className="booking-title">{t('booking.services')}:</h6>
      <div className="booking-services">
        {services.map(renderService)}
      </div>

      {technician ? (
        <>
          <h6 className="booking-title">{t('booking.technicians')}:</h6>
          <TechnicianCard technician={technician} />
        </>
      ) : null}

      {note ? (
        <div className="booking-note-view">
          <h6 className="booking-title">{t('booking.note')}:</h6>
          <h6 className="booking-info-address">{note}</h6>
        </div>
      ) : null}

      <div className={styles.btns}>
        <button onClick={onRegister} className='booking-add-booking-button'>
          <p className="booking-add-service-button-text">{t('booking.createAnAccount')}</p>
        </button>

        <p className={styles.alreadyAccount}>{t('booking.alreadyAnAccount')}</p>
        <button onClick={onLogin} className='booking-add-booking-button booking-login-booking-button'>
          <p className="booking-add-service-button-text">{t('booking.loginNow')}</p>
        </button>
      </div>
    </div>
  )
})

const BookingTemplate1 = (props: any) => {
  const { salon, times, dateComing, timeComing, services, technician, onSelectDay, note, googleCaptchaToken,
    onSelectTime, handleStateChange, onBooking, name, phone, isLoading, bookingSuccess, email,
    showError, refCode } = props
  const days = Array.from({ length: 7 }, (_, i) => i).map(i => moment().add(i, 'days'))
  const avatar = salon.images.find((s: any) => s.type === 1)
  const { t } = useTranslation()
  const styles = useStyles()

  const onCloseModal = () => modalRef?.current?.close()
  const onSelectTech = (tech: any) => handleStateChange(tech, 'technician')
  const onChangePhone = (e: any) => handleStateChange(e.target.value, 'phone')
  const onChangeName = (e: any) => handleStateChange(e.target.value, 'name')
  const onChangeEmail = (e: any) => handleStateChange(e.target.value, 'email')
  const onChangeNote = (e: any) => handleStateChange(e.target.value, 'note')


  const onOpenAddServiceModal = () => {
    modalRef?.current?.open({
      content: <ChooseServices handleStateChange={handleStateChange} services={services} onCloseModal={onCloseModal} salon={salon} />,
    })
  }

  const renderService = (service: any) => <ServiceCard key={service.id} service={service} currency={salon?.timezone?.currency} />
  const renderTechnician = (tech: any) => <TechnicianCard technician={tech} isActive={technician?.id === tech.id} onSelectTech={onSelectTech} />

  const techIds = services.reduce((arr: any, s: any) => {
    const tService = salon?.techniciansServices?.find((ts: any) => ts.serviceId === s.id)
    if (tService && !arr.includes(tService.technicianId)) arr.push(tService.technicianId)
    return arr
  }, [])
  const techs = salon.technicians.filter((t: any) => techIds.includes(t.id))

  return salon ? (
    <div className="booking-template1">
      {salon.slideBanners.length ? (
        <div className={styles.salonBannerContainer} >
          <ImageGallery items={salon.slideBanners} showThumbnails={false} autoPlay showNav={false} showPlayButton={false} />
        </div>
      ) : null}  
      
      <div className={styles.viewSalonInfo}>
        <img alt={salon.name} src={`${process.env.REACT_APP_MEDIA_URL}/salons/${avatar?.raw}`} className={styles.avatar} />
        <div className="booking-info">
          <p className="booking-salon-name">{salon?.name}</p>
          <a href={`mailto:${salon?.email}`}><p className="booking-salon-info-text">{salon?.email}</p></a>
          <a href={`tel:${salon?.phone}`}><p className="booking-salon-info-text">{salon?.phone}</p></a>
        </div>
      </div>
      <h6 className="booking-info-address">{salon?.location?.address}</h6>
      {bookingSuccess ? (
        <BookingSuccessView
          t={t} name={name} phone={phone} email={email} dateComing={dateComing}
          timeComing={timeComing} services={services} renderService={renderService}
          technician={technician} note={note} refCode={refCode}
        />
      ) : (
        <div className={styles.sectionList}>
          <div className={styles.contentRow}>
            <div className={styles.rowLabel}>
              <IoPersonOutline size={20} />
              <span className={styles.label}>{t('booking.fullName')}</span>
            </div>
            <div className={styles.rowItemContent}>
              <input type="text" className={styles.bookingInput} value={name} onChange={onChangeName} />
            </div>
          </div>

          <div className={styles.contentRow}>
            <div className={styles.rowLabel}>
              <IoCallOutline size={20} />
              <span className={styles.label}>{t('booking.phone')}{phone.length > 0 ? ` • ${phone.length}` : ''} {(showError && !stringUtils.validatePhone(phone)) ? <h6 className="booking-msg-invalid">{t('booking.msgInvalidPhone')}</h6> : ''}</span>
            </div>
            <div className={styles.rowItemContent}>
              <input type="tel" className={styles.bookingInput} value={phone} onChange={onChangePhone} />
            </div>
          </div>

          <div className={styles.contentRow}>
            <div className={styles.rowLabel}>
              <IoMailOutline size={20} />
              <span className={styles.label}>
                {t('booking.email')} ({t('booking.optional')}) {(showError && email && !stringUtils.validateEmail(email)) ? <h6 className="booking-msg-invalid">{t('booking.msgInvalidEmail')}</h6> : ''}
              </span>
            </div>
            <div className={styles.rowItemContent}>
              <input type="text" className={styles.bookingInput} value={email} onChange={onChangeEmail} />
            </div>
          </div>

          <div className={styles.contentRow}>
            <div className={styles.rowLabel}>
              <IoFileTrayFullOutline size={20} />
              <span className={styles.label}>{t('booking.services')}</span>
              <span className={styles.labelButton} onClick={onOpenAddServiceModal}>Choose services</span>
            </div>
            <div className={styles.rowItemContent}>
              {services?.length > 0
                ? services.map(renderService)
                : <EmptyState imgUrl={bookingEmpty} text="No services selected" classNameImage={styles.noSelected} />
              }
            </div>
          </div>


          <div className={styles.contentRow}>
            <div className={styles.rowLabel}>
              <IoCalendarClearOutline size={20} />
              <span className={styles.label}>{t('booking.dayComing')}</span>
            </div>
            <div className={styles.rowItemContent}>
              <div className={styles.rowDate}>
                {days?.length
                  ? days?.map((day: any) => (
                    <DateOptionCard key={day.format('DD')} isActive={dateComing.isSame(day, 'day')} date={day} onSelect={onSelectDay} />
                  ))
                  : <p className={styles.redText}>{t('booking.closed')}</p>
                }
              </div>
            </div>
          </div>

          <div className={styles.contentRow}>
            <div className={styles.rowLabel}>
              <IoCalendarClearOutline size={20} />
              <span className={styles.label}>{t('booking.timeComing')}</span>
            </div>
            <div className={styles.rowItemContent}>
              <div className={styles.rowTime}>
                {times.length ?
                  times?.map((time: any) => (
                    <TimeOptionCard key={time} isActive={timeComing && timeComing.isSame(time, 'minutes')} time={time} onSelect={onSelectTime} />
                  ))
                  : <p className={styles.redText}>{t('booking.closed')}</p>
                }
              </div>
            </div>
          </div>

          {techs.length ? (
            <div className={styles.contentRow}>
              <div className={styles.rowLabel}>
                <IoPeopleCircleOutline size={24} />
                <span className={styles.label}>{t('booking.technicians')} ({t('booking.optional')})</span>
              </div>
              <div className={styles.rowItemContent}>
                {salon.technicians.map(renderTechnician)}
              </div>
            </div>
          ) : null}

          <div className={styles.contentRow}>
            <div className={styles.rowLabel}>
              <IoCreateOutline size={24} />
              <span className={styles.label}>{t('booking.note')} ({t('booking.optional')})</span>
            </div>
            <div className={styles.spaceLeft}>
              <textarea value={note} onChange={onChangeNote} className={`${styles.bookingInput} ${styles.bookingNoteInput}`} />
            </div>
          </div>

          <button onClick={onBooking} disabled={isLoading || !name || !phone || !dateComing || !timeComing || services.length === 0 || !googleCaptchaToken} className='booking-add-booking-button'>
            {isLoading
              ? <Circles ariaLabel="loading-indicator" color="#E09672" width="20px" height="20px" />
              : <p className="booking-add-service-button-text">{t('booking.bookAnAppointment')}</p>}
          </button>
        </div>
      )}
    </div>
  ) : null
}

export default BookingTemplate1
