export enum SalonImageType {
	Avatar = 1,
	Banner = 2,
}


export default class SalonImageModel {
  public id!: number
  public salonId!: number
  public thumbnail!: string
  public raw!: string
  public type!: number

  constructor(data: any) {
    if (data) {
      this.id = data.id
      this.salonId = data.salon_id
      this.thumbnail = data.thumbnail
      this.raw = data.raw
      this.type = data.type
    }
  }
}