import {
  Routes,
  Route,
} from "react-router-dom"
import './App.css';
import Booking from './containers/booking'

function App() {
  return (
    <Routes>
      <Route path="/:slug" element={<Booking />} />
    </Routes>
  )
}

export default App;
