
export enum SalonStaffRole {
  Admin = 1,
  Cashier = 2,
  Technician = 3,
}

export default class TechnicianModel {
  public id!: number
  public userId!: number
  public salonId!: number
  public role!: number
  public name!: string
  public email!: string
  public phone!: string
  public revenueRate!: number
  public raw!: string
  public thumbnail!: string
  public createdAt!: string
  public updatedAt!: string
  public serviceIds!: number[]
  public reports!: any
  public avatarColorIdx!: number

  constructor(data: any) {
    if (data) {
      this.id = data.id
      this.userId = data.user_id
      this.salonId = data.salon_id
      this.role = data.role
      this.name = data.name
      this.email = data.email
      this.phone = data.phone
      this.revenueRate = data.revenue_rate
      this.raw = data.raw
      this.thumbnail = data.thumbnail
      this.createdAt = data.created_at
      this.updatedAt = data.updated_at
      this.serviceIds = data.serviceIds ?? []
      this.reports = data.reports || {}
      this.avatarColorIdx = data.avatar_color_idx
    }
  }

  public getUrl(): string {
    return `${process.env.REACT_APP_MEDIA_URL}/users/`
  }

  public avatarUrl(): string {
    return this.thumbnail
      ? `${process.env.REACT_APP_MEDIA_URL}/users/${this.thumbnail}`
      : ''
  }
}
