import React from 'react'

import { ServiceCard } from 'components'
import useStyles from './styles'
import { IoChevronForwardOutline, IoChevronDownOutline } from 'react-icons/io5'

const Services = React.memo(({ services, salon, selected, onSelected }: any) => {
  const renderService = (service: any) => (
    <ServiceCard
      currency={salon?.timezone?.currency} key={service.id} service={service}
      isActive={selected?.some((s: any) => s.id === service.id)} onSelected={onSelected}
    />
  )


  return services.map(renderService)
})

const Category = React.memo(({ salon, category, isHidden, selected, onToggleCategory, onSelected }: any) => {
  const styles = useStyles()
  const services = salon.services.filter((s: any) => s.categoryId === category.id)
  const onClick = () => onToggleCategory(category?.id)

  return (
    <div key={category?.id}>
      <div className={styles.viewCategory} onClick={onClick}>
        <p className={styles.categoryName}>{category?.name}</p>
        {isHidden
          ? <IoChevronDownOutline size={20} />
          : <IoChevronForwardOutline size={20} />}
      </div>
      {isHidden
        ? <Services services={services} salon={salon} selected={selected} onSelected={onSelected} />
        : null}
    </div>
  )
})

const SectionList = React.memo((props: any) => {
  const { salon, selected, onSelected, hiddenLists, onToggleCategory } = props
  const styles = useStyles()

  const renderCategory = (category: any) => <Category isHidden={!hiddenLists?.includes(category.id)} category={category} salon={salon} selected={selected} onSelected={onSelected} onToggleCategory={onToggleCategory} />

  return (
    <div className={styles.modalList}>
      {salon?.categories.map(renderCategory)}
    </div>
  )
})

export default SectionList
