import { Colors } from 'contants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  container: {
    display: 'inline-block',
    textAlign: 'center',
    float: 'left',
    margin: '0px 10px 10px 0px',
    padding: '10px',
    borderWidth: '0px',
    backgroundColor: '#fff',
    width: '84px'
  },

  itemActive: {
    backgroundColor: '#2245a9',
    borderRadius: '15px'
  },

  txtActive: {
    color: 'white'
  },

  image: {
    width: '64px',
    height: '64px',
    borderRadius: '8px',
    backgroundColor: Colors.WHITE,
    alignItems: 'center',
    justifyContent: 'center'
  },

  empty: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },

  txtChar: {
    fontSize: '22px',
    color: Colors.PRIMARY,
  },

  border: {
    borderColor: '#1769aa',
    borderWidth: 0.8
  },
})

export default useStyles