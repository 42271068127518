import gateway, { APIMethod } from "./gateway"

const API_SERVER = process.env.REACT_APP_SERVER_URL

export const getSalon = (data: object) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/salons/web-booking`, data)
}

export const booking = (data: object) => {
  return gateway.send(APIMethod.POST, `${API_SERVER}/bookings/web`, data)
}

export const addSalonReferralActivity = (salonId: number, refCode: string | null, data: object) => {
  return gateway.send(APIMethod.POST, `${API_SERVER}/salons/${salonId}/referrals/${refCode}/activities`, data)
}
