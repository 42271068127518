import React from 'react'

import { ServiceCard } from 'components'
import useStyles from './styles'
import { useTranslation } from 'react-i18next'

const Services = React.memo(({ services, salon, selected, onSelected }: any) => {
  const renderService = (service: any) => (
    <ServiceCard
      currency={salon?.timezone?.currency} key={service.id} service={service}
      isActive={selected?.some((s: any) => s.id === service.id)} onSelected={onSelected}
    />
  )


  return services.map(renderService)
})

const ServicesSearchResults = React.memo((props: any) => {
  const { searchResults, salon, selected, onSelected } = props
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <div className={styles.modalList}>
      {searchResults.length > 0
        ? <Services services={searchResults} salon={salon} selected={selected} onSelected={onSelected} />
        : <h5 className={styles.txtEmpty}>{t('booking.noServicesWithKeyword')}</h5>}
    </div>
  )
})

export default ServicesSearchResults
