import { BsCheck2, BsClock } from 'react-icons/bs'

import { AiOutlineDollar, AiOutlineClockCircle } from 'react-icons/ai'
import { formatCurrency } from 'utils/currency'
import useStyles from './styles';
import { Avatar } from 'components'
import React from 'react'
import images from 'assets'
import ServiceModel from 'models/service'

type PropTypes = {
  service: ServiceModel
  currency: string
  isActive?: boolean | undefined
  onSelected?: any
}

export const ServiceCard = React.memo((props: PropTypes) => {
  const styles = useStyles()
  const { service, isActive, currency, onSelected } = props

  const onPress = () => {
    if (!onSelected) return null
    onSelected(service)
  }

  return (
    <div className={`${styles.serviceInfo} ${isActive ? styles.techInfoSelected : ''}`} onClick={onPress}>
      <div className={styles.techAvatar}>
        <Avatar avatarUrl={service?.imageUrl() ?? images.logoDefault()} style={styles.techAvatar} />
      </div>
      <div className={styles.content}>
        <div className={styles.techName}>
          {service?.name}
        </div>
        <div className={styles.rowDurationPrice}>
          <div className={styles.priceBox}>
            <AiOutlineDollar size={16} />
            <span className={styles.price}>{formatCurrency(service?.price?.toString(), currency)}</span>
            {service?.refPrice
              ? <span className={styles.refPrice}>{formatCurrency(service?.refPrice?.toString(), currency)}</span>
              : null}
          </div>
          <div className={styles.durationBox}>
            <AiOutlineClockCircle size={16} />
            <span className={styles.duration}>{service?.duration} m</span>
          </div>
        </div>
        {isActive ? <span className={styles.checked}><BsCheck2 /></span> : null}
      </div>
    </div>
  )
})
