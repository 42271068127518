import { all } from 'redux-saga/effects'

// USERS

function* rootSagas() {
  yield all([
    // init(),
  ])
}

export default rootSagas