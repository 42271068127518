import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  //service card    
  serviceInfo: {
    display: 'flex',
    flex: 1,
    flexDirection: 'col',
    alignItems: 'flex-start',
    padding: '0.5rem',
    border: '1px solid #ccc',
    borderRadius: '0.5rem',
    margin: '0.5rem 0',
    position: 'relative',
  },

  content: {
    flex: 1,
    paddingLeft: '5px'
  },

  techInfoSelected: {
    border: '1px solid #2245a9',
  },

  techName: {
    display: 'flex',
    fontSize: '14px',
    color: '#3C3C3E',
    fontWeight: '700',
    padding: '0.1rem 0.5rem',
  },

  rowDurationPrice: {
    width: '100%',
    flex: 1,
    alignItems: 'center',
  },

  durationBox: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    color: '#3C3C3E',
    padding: '0 0.5rem',
  },

  duration: {
    fontSize: '14px',
    color: '#3C3C3E',
    fontWeight: '400',
    marginLeft: '0.5rem',
  },

  priceBox: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '14px',
    color: '#3C3C3E',
    fontWeight: '400',
    padding: '0 0.5rem',
  },

  price: {
    fontSize: '14px',
    color: '#3C3C3E',
    fontWeight: '400',
    marginLeft: '0.5rem',
  },

  refPrice: {
    fontSize: '12px',
    color: '#999',
    fontWeight: '400',
    marginLeft: '0.2rem',
    textDecoration: 'line-through'
  },

  icon: {
    display: 'flex',
  },

  checked: {
    display: 'flex',
    position: 'absolute',
    top: 5,
    right: 5,
    padding: '0.2rem',
    borderRadius: '50%',
    backgroundColor: '#2245a9',
    color: '#fff',
    fontSize: '16px',
    fontWeight: '400',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #ccc',
  }
})

export default useStyles