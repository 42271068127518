import moment from "moment"

export default class SalonWorkingTimeModel {
  public id!: number
  public salonId!: number
  public weekday!: number
  public from!: string
  public to!: string

  constructor(data: any) {
    if (data) {
      this.id = data.id
      this.salonId = data.salon_id
      this.weekday = data.weekday

      this.from = data.from.indexOf('AM') > -1 || data.from.indexOf('PM') > -1
        ? moment(data.from, 'h:mm A').format('HH:mm')
        : data.from

      this.to = data.to.indexOf('AM') > -1 || data.to.indexOf('PM') > -1
        ? moment(data.to, 'h:mm A').format('HH:mm')
        : data.to
    }
  }
}