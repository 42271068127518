import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import enUS from './lang/en'
import nnNO from './lang/vi'

i18next.use(initReactI18next).init({
  lng: 'en',
  debug: true,
  resources: {
    en: {
      translation: enUS
    },
    vi: {
      translation: nnNO
    }
  }
})

export default i18next
